import React, { useState } from 'react';
import { motion } from 'framer-motion';

import './PhotoGallery.css'; // Import your CSS file for styling


const Ring = ({displayRing}) => {
    const [fontSize, setFontSize] = useState(1);
    const handleRingClick = () => {
        if (fontSize < 31) { setFontSize(fontSize + 0.3) };
    };

    return (
        <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        style = {{ 'display': (displayRing ? "block" : "none") }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20
        }}
        >
            <div className="ring" onClick={() => handleRingClick()} style={{ fontSize }}>
                💍
            </div>
        </motion.div>
    );
}

export default Ring;
