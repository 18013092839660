import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './PhotoGallery.css'; // Import your CSS file for styling
import Ring from './Ring';

const PhotoGallery = () => {
  const [counter, setCounter] = useState(0);
  const [displayRing, setDisplayRing] = useState(false);
  const [photos, setPhotos] = useState(Array.from(
    [
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/f0eec71b-1953-4373-0e33-266435603a00/public", id: "hehe_20221212_4.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/6d9dd049-07d2-4fc1-bbe3-2233ad715000/public", id: "hehe_20171214_3.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/fb6e6d81-4fc7-45d0-d30b-7c4d18794800/public", id: "hehe_20231228_1.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/9478c367-d28c-4082-2625-b15a3200c400/public", id: "hehe_20240406_2.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/046e0b8d-7560-47be-4993-2197453dad00/public", id: "hehe_20220402_0.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/28168565-43b2-4c1a-d7ce-4b3c50035900/public", id: "hehe_20240406_3.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/9bf49660-d2bf-4382-e910-91cd32ffd900/public", id: "hehe_20240214_4.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/37bbb2a1-059d-47c9-ddbe-ba709528a100/public", id: "hehe_20230129_0.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/67ac2e18-7409-42d1-4633-df40fe7cba00/public", id: "hehe_20220707_4.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/c6a1fd4d-18a2-41a0-785f-a1bc41a6de00/public", id: "hehe_20221212_5.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/4e26f7b6-ae73-4e7e-c11f-7b32c8d89b00/public", id: "hehe_20221212_7.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/e58586fb-bc2f-4a63-b989-e71616d67b00/public", id: "hehe_20240214_6.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/ae5e8072-cb11-4e0b-a126-33e5cb245800/public", id: "hehe_20240406_1.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/0bb5705d-0aae-44e2-a364-eb83d1554f00/public", id: "hehe_20220108_4.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/3a120a19-ca44-434d-a154-328ba5078b00/public", id: "hehe_20220426_2.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/721aa025-f4e5-46e6-67cf-558a33d2f200/public", id: "hehe_20240406_0.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/b02eb53f-aeba-442b-9f7d-1d7f10e68200/public", id: "hehe_20230228_3.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/b128ba71-ff12-44b9-cdd9-46c8ba9e3b00/public", id: "hehe_20221210_4.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/eb149bac-bc25-4654-8b9d-0440212e3300/public", id: "hehe_20221212_6.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/83564c12-e0b8-47fc-1cda-ac921c201d00/public", id: "hehe_20220707_3.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/9cc6e00b-7388-43e7-1105-3c8d8554df00/public", id: "hehe_20221212_2.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/f4d41ec4-63cf-469c-2835-82f0f0ed5700/public", id: "hehe_0.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/6ae93a68-00b2-4ae9-3cac-7b088defe100/public", id: "hehe_20240214_3.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/07fdfc62-b69c-49a1-2ca2-768661fe4900/public", id: "hehe_20240406_4.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/ea2820ab-b837-43e8-65d9-92663f1bf100/public", id: "hehe_20230427_5.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/880105a0-3099-42b3-3002-ae604f9e0a00/public", id: "hehe_20230501_3.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/3dadf6c6-d776-497f-bb81-0017ac48ba00/public", id: "hehe_20180923_1.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/b6a5d1f3-a43f-4f2b-d99b-676db72ed100/public", id: "hehe_20211031_9.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/d1a5438b-d83d-41e9-c36b-d5883af60e00/public", id: "hehe_20240214_2.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/8b81204a-be12-4f94-0e20-9f2b77dc5d00/public", id: "hehe_20171214_4.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/a81e621d-70bc-4958-62fe-99aeaa17f800/public", id: "hehe_20230930_1.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/d68034f1-5268-4000-9134-2c0300b8ef00/public", id: "hehe_20221212_3.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/f69dc185-37af-471d-642e-86191751f000/public", id: "hehe_20220707_2.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/19aaeb60-3b47-4764-1d7b-903a9d23fa00/public", id: "hehe_20211225_2.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/aa0ae9c0-12cb-486b-cd1e-d22ad8b5b500/public", id: "hehe_20221212_1.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/e426f9f6-da45-4664-04ea-295ddc655300/public", id: "hehe_20230228_4.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/b26fa688-6244-482d-6218-9522a18bdd00/public", id: "hehe_20240406_7.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/3032137e-e2a4-4427-8685-57d290270200/public", id: "hehe_20230427_6.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/af7f05d9-6589-4129-42d2-97688922f700/public", id: "hehe_20180923_3.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/35cda566-3781-4919-e62a-1851f3fa7d00/public", id: "hehe_20180923_2.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/8d19a2d8-e596-46f7-a1ce-afdea6981800/public", id: "hehe_20230427_7.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/4d9fe825-9b6f-4ab6-ee70-51d251cd2600/public", id: "hehe_20180324_7.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/0917cc1b-7d47-4176-a2c6-f98f27a67500/public", id: "hehe_20180708_7.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/59df2734-83b2-4b89-5249-29234924c600/public", id: "hehe_20180921_0.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/1de5fc73-d5be-4a27-3945-d5707c42b800/public", id: "hehe_20221212_0.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/d933f052-9c8b-41f3-d381-a5679c9d6500/public", id: "hehe_20220427_3.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/a33b1ef3-dbd0-4e37-2dba-bf596b9bab00/public", id: "hehe_20220425_1.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/89120e62-0810-4575-f5a4-5701ca222900/public", id: "hehe_20221211_5.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/f5999f2b-d233-4018-c522-da19bcb01500/public", id: "hehe_20221213_7.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/2ddf9941-8abe-4c89-e094-07261ead9d00/public", id: "hehe_20220708_9.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/768bb28d-278e-4637-22b6-9eeb27ec1100/public", id: "hehe_20220708_8.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/3598c436-d1f9-4d47-86ba-d2ea71845d00/public", id: "hehe_20231212_5.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/c059293d-56b2-4ad8-ea63-fadb67a1dd00/public", id: "hehe_20181201_5.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/10cb4efd-8ffb-442c-fed8-352446a19500/public", id: "hehe_20231209_4.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/b2f6b0a7-78b0-45ba-011e-2c53728f3a00/public", id: "hehe_20221211_6.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/86c5fa03-5458-4655-2f6c-18752fcc4000/public", id: "hehe_20231212_7.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/eb1af7ec-beb7-4f22-1e52-4c31a43dc600/public", id: "hehe_20230723_7.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/17d4ac6a-ed64-4d64-5300-f5da62fe7a00/public", id: "hehe_20231212_6.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/a0849921-59ae-402f-3bfb-c765e19b6000/public", id: "hehe_20180126_5.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/34cece03-90a9-40ce-ec77-59208ef6f500/public", id: "hehe_20230725_0.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/5ee11c15-c03e-46ba-b1b8-5aa5d02b6a00/public", id: "hehe_20230428_9.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/271af439-0ac3-4e2a-981d-bff32be91a00/public", id: "hehe_20220217_5.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/e8abadd7-037f-491a-0d66-74cc83d6e500/public", id: "hehe_20220521_6.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/aea087bc-e3ef-4a97-7825-e207295b8800/public", id: "hehe_20220706_0.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/96aa884f-03c5-4cd1-ca21-3e839874da00/public", id: "hehe_20220706_1.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/9919f96d-bac5-4357-ca63-41b21ad9fc00/public", id: "hehe_20220521_7.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/c08847f3-9efa-4b58-e85c-8e83ff857300/public", id: "hehe_20230428_8.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/2428ee12-953c-4481-6285-9f8b475b6e00/public", id: "hehe_20180915_9.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/160811b3-a92e-4b46-6e7d-60ef1fdac900/public", id: "hehe_20190112_8.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/3df5298b-0b6a-4ce3-163b-e9ce3365cf00/public", id: "hehe_20220521_5.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/5018b65b-5a54-4fbc-32cd-6246b952ee00/public", id: "hehe_20230904_9.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/50e03ed5-3ee6-4bfd-ee7b-5e49d5994d00/public", id: "hehe_20230904_8.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/14f5fe08-edec-44d9-5de6-597c9c896a00/public", id: "hehe_20230126_9.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/1703bc0a-5751-4326-c0f6-aa3f14da2e00/public", id: "hehe_20180330_8.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/cab6786e-63f5-4047-5abf-e43d96764500/public", id: "hehe_20230902_3.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/2a794612-a456-4518-1efa-62ee09e70200/public", id: "hehe_20220311_9.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/c94a396e-9084-412e-7cca-4debf49ef000/public", id: "hehe_20220226_7.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/99112110-2b01-4d0e-0ec1-23e1cc88dc00/public", id: "hehe_20220708_0.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/6d79af1e-dd51-406e-b581-8c3bd3608d00/public", id: "hehe_20220708_1.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/3769a588-3e96-4f6e-9c86-3eae651c7700/public", id: "hehe_20220311_8.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/c5a5d5a6-c9e6-4ad9-5f50-f0f3980aad00/public", id: "hehe_20180625_5.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/e4171159-6da4-40db-922e-081aa6743000/public", id: "hehe_20180623_3.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/86622f8e-f9a5-435a-706e-4fe56a35ca00/public", id: "hehe_20230902_2.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/9b6cce8d-6f58-4847-a556-efc003e91500/public", id: "hehe_20231216_8.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/70b59e24-8a71-4912-d13a-563e337bf800/public", id: "hehe_20180522_1.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/9ef3d721-bd99-450c-e9b8-a667bc99ae00/public", id: "hehe_20171204_2.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/1640c77a-34ff-4111-2ff3-3f1316beb500/public", id: "hehe_20220708_2.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/ff04fafd-b8cc-4a0d-ddfc-5f386c6f7700/public", id: "hehe_20180625_6.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/931aa906-d4d1-45bc-5846-8451103a7500/public", id: "hehe_20231002_2.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/c05fd26b-55d5-4c5c-0cd3-58a05d12ac00/public", id: "hehe_20180522_0.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/fbaea9a8-a26d-4052-6148-32c305b7d600/public", id: "hehe_20230902_1.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/7bafd75d-2f1a-4b99-4f54-710fe2145e00/public", id: "hehe_20211231_3.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/375058fe-5e85-41fe-f69e-a9f3368cbd00/public", id: "hehe_20230528_5.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/a44e78d3-9d9f-41d9-5c4f-0ce01d435b00/public", id: "hehe_20221213_8.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/e34fe93a-42ba-48fe-fd15-442d52ace100/public", id: "hehe_20220706_9.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/da5e9757-bcfd-4b0b-fc1c-45bfc21d0000/public", id: "hehe_20220708_6.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/93e0d17a-fb4b-481d-02a4-30325e900d00/public", id: "hehe_20220708_7.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/6c59a448-b61e-4049-b821-a0c613788900/public", id: "hehe_20220706_8.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/305d9dd4-f919-4253-c2ee-6700efac1c00/public", id: "hehe_20180819_8.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/f139a136-f55b-48c4-66cf-8f9a2e66fc00/public", id: "hehe_20220220_6.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/ae077a4d-46c7-446d-f9b9-a2bd341b5200/public", id: "hehe_20230528_4.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/867f10b3-1bb4-4e78-72f0-fe528b298500/public", id: "hehe_20180428_9.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/7341f4e2-080d-45a4-93c5-ab928ac7ef00/public", id: "hehe_20230723_8.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/656c5575-7678-4feb-47d0-7729bd034500/public", id: "hehe_20220708_5.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/0d1dfa89-75ec-4374-67dc-3d9f9c0fcf00/public", id: "hehe_20230225_2.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/5a727c3f-6969-447b-9256-42bb03a76c00/public", id: "hehe_20231227_0.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/fc82db03-f85e-4d30-ee59-6b9434fe0000/public", id: "hehe_20181211_6.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/1c1c9f78-a6f9-46c1-235e-878ee97c2000/public", id: "hehe_20230724_9.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/5efbce2c-cda4-42f5-dbd8-7484d4989900/public", id: "hehe_20230717_6.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/40cb5638-b718-4811-c424-e13555741a00/public", id: "hehe_20181014_4.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/b9afa053-e3e5-421e-794a-67787066ab00/public", id: "hehe_20180624_4.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/8a53fd23-7b09-4cae-6d2c-8cebe3706a00/public", id: "hehe_20240406_8.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/800d3897-ed65-451f-3a47-b5d6d60e9400/public", id: "hehe_20231217_9.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/9b863a7a-e810-4fb3-fccc-3f1bf7c96a00/public", id: "hehe_20231204_3.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/a918d964-1bd4-4c46-758b-3318ac68b000/public", id: "hehe_20240406_9.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/95cdfe47-2eab-4cfb-4bb4-221997cc1400/public", id: "hehe_20230430_2.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/da654185-4858-49cf-4184-38d9194a1a00/public", id: "hehe_20230905_0.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/a9c188d2-7633-40b7-e5a2-1d8c932dbe00/public", id: "hehe_20230903_6.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/16a19952-eb9d-484c-6250-a93189e61500/public", id: "hehe_20230903_7.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/9326a405-61b6-46dc-9d59-869dcda41200/public", id: "hehe_20211031_0.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/92aa92e9-6842-4190-026f-9a8790b77100/public", id: "hehe_20220515_4.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/d60991b8-a9eb-4232-ca76-c69f8e89d300/public", id: "hehe_20230202_1.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/63b38d82-4fb1-4284-209e-a80ec54b1f00/public", id: "hehe_20221212_8.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/d21c5f48-5771-4f53-43d1-f3d3f2f73a00/public", id: "hehe_20181215_7.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/f147e8d8-dcb0-430e-f458-7ee306b22000/public", id: "hehe_20180619_2.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/f1943a28-b4f7-4fc2-8b9c-47ea784fba00/public", id: "hehe_20230429_0.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/6077017b-fd54-4523-5804-ee303ff53e00/public", id: "hehe_20230903_5.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/bd76f014-767c-42cc-23d6-a700ab64ea00/public", id: "hehe_20230903_4.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/a7354d84-32db-4f95-2410-8c3e102b7300/public", id: "hehe_20230429_1.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/a9167bbd-a898-45c1-62da-69925c38e200/public", id: "hehe_20211212_1.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/86119814-c994-4705-a70c-82d06d93ee00/public", id: "hehe_20220730_3.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/b7b72c1c-cb96-4a75-145b-9ce103785900/public", id: "hehe_20180308_6.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/ce84381c-0a92-45be-4b88-9ffa6fb07900/public", id: "hehe_20221212_9.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/c17f7858-a329-4e7d-5c9f-a020bd7d0b00/public", id: "hehe_20160611_1.jpg" },
      { url: "https://imagedelivery.net/FOLclBqVTeiMr6xZchb5XA/cf912696-7382-4c99-11b5-ef29f3267100/public", id: "hehe_20240503.jpg" },
    ]
  ));
  // const [photos, setPhotos] = useState(Array.from(
  //   [
  //     { id: 1, url: 'https://via.placeholder.com/300' },
  //     { id: 2, url: 'https://via.placeholder.com/300' },
  //     { id: 3, url: 'https://via.placeholder.com/300' },
  //     { id: 4, url: 'https://via.placeholder.com/300' }
  //   ]
  // ));


  // Function to increase counter by 10 when an image is clicked
  const handleImageClick = (p) => {
    setCounter(prevCounter => prevCounter + 100);
    if (counter >= 10000 && photos.length < 4) {
      setDisplayRing(true);
    }
    const newPhotos = photos.filter(photo => photo.id !== p.id);
    if (newPhotos.length > 0) {
      setPhotos(newPhotos);
    }

  };

  // Decrease counter by 5 every 300 milliseconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(prevCounter => prevCounter - 1 > 0 ? prevCounter - 1 : 0);
    }, 10);

    // Clear the interval if counter reaches a specific value
    if (counter >= 10000) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [counter]); // Run only once on component mount

  // Calculate background color based on counter value
  const backgroundColor = `rgba(${255 * (counter / 10000)}, 50, 50, 1)`;


  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.2,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 100,
        damping: 10
      }
    }
  };

  return (
    <>
    <Ring displayRing = {displayRing} />
    <motion.div
      className="photo-gallery"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      style={{ "display": displayRing ? "none" : "block"}}
    >
      <div className="counter" style={{ backgroundColor }}>{counter}</div> {/* Display the counter */}
      <div className="photo-grid">
        {photos.map(photo => (
          <motion.div
            key={photo.id}
            className="photo-item"
            variants={itemVariants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => handleImageClick(photo)} // Call handleImageClick when image is clicked
          >
            <img src={photo.url} alt="" />
          </motion.div>
        ))}
      </div>
    </motion.div>
    </>
  );
}

export default PhotoGallery;
