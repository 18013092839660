import React from 'react';
import PhotoGallery from './components/PhotoGallery';

const App = () => {
  return (
    <div>
      {/* Other components and content */}
      <PhotoGallery />

      {/* Other components and content */}
    </div>
  );
}

export default App;